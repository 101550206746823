import React from 'react'
import { Form } from 'pages'
import { Sidebar } from 'components/resource/sidebar'
// import { BlockCollection } from 'components/block'
import schema from '_schema/publishingHousePublication'
import routes from './routes'
import { ManualNextRevalidatorButton } from 'components/ManualNextRevalidatorButton'
import { EmbeddedCollection } from 'components/embedded'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitions={definitions}
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor="title"
      SidebarComponent={Sidebar}
      width={600}
      header={resource => (
        <ManualNextRevalidatorButton
          type="publishing_house_publication"
          uuid={resource.uuid}
        />
      )}
    >
      {/* <BlockCollection
        endpoint={schema.subresources.block.endpoint}
        parentIri={iri}
        definitions={definitions}
        definitionSchema={definitions[schema.subresources.block.definition]}
        types={schema.subresources.block.types}
        statable={true}
        key={iri}
      /> */}
      <EmbeddedCollection
        endpoint={schema.subresources.authorRelations.endpoint}
        parentIri={iri}
        properties={schema.subresources.authorRelations.properties}
        definitions={definitions}
        definitionSchema={
          definitions[schema.subresources.authorRelations.definition]
        }
        statable={true}
        headerTitle="Autorzy"
        titleAccessor="author.title"
        key={`${iri}-author-relations`}
      />
    </Form>
  )
}
