import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import serieScheme from './publishingHouseSerie'
import mediaSchema from '_schema/media'
import { LANGS_IRI } from '_lib/langs'
import _block from './_block'
import ageRestrictionsSchema from '_schema/_enums/ageRestriction'
import publishingHouseAuthorSchema from '_schema/publishingHouseAuthor'

const schema = {
  endpoint: '/api/publishing_house_publications',
  access: 'publishingHousePublication',
  resource: {
    definition: '#PublishingHousePublication',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
          // showMoreLink: {
          //   description: 'Link przycisku "pokaż więcej"',
          // },
          // metaTitle: {},
          // metaDescription: {
          //   type: FIELD_TYPES.TEXTAREA,
          // },
          // metaKeywords: {
          //   type: FIELD_TYPES.TEXTAREA,
          // },
          // alt: {},
          // slug: {
          //   type: FIELD_TYPES.SLUG,
          //   routes: {
          //     [LANGS_IRI.PL]: '/wydawnictwo/publikacje/:slug',
          //     [LANGS_IRI.EN]: '/publishing-house/publications/:slug',
          //   },
          // },
        },
      },
      media: {
        type: FIELD_TYPES.IMAGE,
        description: 'T_GENERAL_PHOTO',
        endpoint: mediaSchema.endpoint,
        thumbs: {
          endpoint: '/api/publishing_house_publication_thumbs',
        },
        validate: ['maxSize'],
        handleFileManagerMetadata: (setValue, media) => {
          setValue('injectTranslations', {
            [LANGS_IRI.PL]: { alt: media.translations[LANGS_IRI.PL].alt },
            [LANGS_IRI.EN]: { alt: media.translations[LANGS_IRI.EN].alt },
          })
        },
      },
      iksorisId: {
        type: FIELD_TYPES.IKSORIS,
        endpoint: '/api/iksoris/wares',
        titleAccessor: 'name',
        label: 'Publikacja',
      },
      serie: {
        type: FIELD_TYPES.RESOURCE,
        endpoint: `${serieScheme.endpoint}?pagination=false`,
        titleAccessor: `translations.${
          LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
        }.title`,
      },
      // ageRestriction: {
      //   description: 'Ograniczenie wiekowe',
      //   type: FIELD_TYPES.RESOURCE,
      //   titleAccessor: 'title',
      //   endpoint: ageRestrictionsSchema.endpoint,
      // },
      // isShowMoreEnabled: {
      //   description: 'Włącz przycisk "pokaż więcej"',
      //   type: FIELD_TYPES.BOOLEAN,
      // },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/publishing_house_publication_blocks',
      definition: '#PublishingHousePublicationBlock',
      types: [..._block('publishing_house_publication').types],
    },
    authorRelations: {
      endpoint: '/api/publishing_house_publication_authors',
      definition: '#PublishingHousePublicationAuthor',
      properties: {
        author: {
          type: FIELD_TYPES.AUTOCOMPLETE,
          titleProperty: 'title',
          searchProperty: 'title',
          endpoint: publishingHouseAuthorSchema.endpoint,
          validate: ['required'],
        },
      },
    },
  },
}

export default schema
