import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { Title } from 'components/Title'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { DatetimeCell } from 'components/table/cells/DatetimeCell'
import { LinkCell } from './table/cells/LinkCell'
import { StateCell } from './table/cells/StateCell'
import { translate } from '_helpers/translate'
import schema from '_schema/clonerQueueItem'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        accessor: 'adminTitle',
        sortable: true,
        Cell: LinkCell,
        width: '36%',
      },
      {
        accessor: 'state',
        Cell: StateCell,
        sortable: true,
        width: '12%',
      },
      {
        accessor: 'createdAt',
        sortable: true,
        width: '15%',
        header: 'Data dodania',
        Cell: DatetimeCell,
      },
      {
        accessor: 'clonedAt',
        sortable: true,
        width: '15%',
        header: 'Data sklonowania',
        Cell: DatetimeCell,
      },
      {
        accessor: 'addedByFullName',
        header: 'Dodany przez',
        sortable: true,
        width: '22%',
      },
    ],
    []
  )

  return (
    <Paper>
      <Title>{translate(routes().index.title)}</Title>
      <SchemableCollection
        component={CollectionTable}
        definition={schema.resource.definition}
        endpoint={schema.endpoint}
        columns={columns}
        autoWidth={false}
        defaultSorters={{ createdAt: 'desc' }}
        parameters={[
          {
            name: 'order[state]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[adminTitle]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[createdAt]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[clonedAt]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
      />
    </Paper>
  )
}
