import React from 'react'
import { Clone } from 'components/resource/Clone'
import { constants } from 'components/table/_state'

export const CloneCell = ({
  resource,
  endpoint = null,
  disabled = false,
  onSuccess: customOnSuccess = null,
  adminTitle = null,
  adminUrl,
  tableStateDispatch,
}) => {
  const onSuccess = () => {
    customOnSuccess && customOnSuccess()

    if (!endpoint) {
      return
    }

    tableStateDispatch({ type: constants.RELOAD })
  }

  return (
    <Clone
      resource={resource}
      endpoint={endpoint}
      disabled={disabled}
      adminTitle={adminTitle}
      adminUrl={adminUrl}
      onSuccess={onSuccess}
    />
  )
}
