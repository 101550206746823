import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import textEntryTypesSchema from '_schema/_enums/textEntryType'

const ENTRIES_ENDPOINT = '/api/<TYPE>_block_text_entries'

export const textEntries = type => ({
  name: BLOCK_TYPES_IRI.TEXT_ENTRIES,
  label: 'Kolofon',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
        textEntriesBody: {
          description: 'Zawartość',
          type: FIELD_TYPES.TINYMCE,
        },
      },
    },
    textEntriesType: {
      type: FIELD_TYPES.RESOURCE,
      description: 'Rodzaj',
      titleAccessor: 'title',
      endpoint: textEntryTypesSchema.endpoint,
      validate: ['required'],
    },
    textEntriesAmountOfCols: {
      description: 'Liczba kolumn',
      type: FIELD_TYPES.INTEGER,
    },
    textEntries: {
      description: 'Etykiety',
      type: FIELD_TYPES.EMBEDDED_COLLECTION,
      endpoint: ENTRIES_ENDPOINT.replace('<TYPE>', type),
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            title: {
              description: 'Etykieta',
              type: FIELD_TYPES.STRING,
            },
            text: {
              description: 'Tekst',
              type: FIELD_TYPES.TINYMCE,
            },
          },
        },
      },
    },
  },
})
