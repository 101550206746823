import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import eventTypeSchema from '_schema/eventType'

export const typedEvents = () => ({
  name: BLOCK_TYPES_IRI.TYPED_EVENTS,
  label: 'Wydarzenia po typie',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
      },
    },
    eventType: {
      type: FIELD_TYPES.RESOURCE,
      description: 'Typ',
      titleAccessor: 'title',
      endpoint: `${eventTypeSchema.endpoint}?pagination=false`,
    },
    calendarMaxEntries: {
      description: 'Max terminów',
      type: FIELD_TYPES.NUMBER,
    },
  },
})
