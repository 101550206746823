import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/event_terms',
  resource: {
    definition: '#EventTerm',
    properties: {
      dateFrom: {
        type: FIELD_TYPES.DATE,
        validate: ['required'],
      },
      timeFrom: {
        type: FIELD_TYPES.TIME,
      },
      dateTo: {
        type: FIELD_TYPES.DATE,
      },
      timeTo: {
        type: FIELD_TYPES.TIME,
      },
    },
  },
  forms: {
    regular: {
      properties: {
        dateFrom: {
          type: FIELD_TYPES.DATE,
          validate: ['required'],
        },
        timeFrom: {
          type: FIELD_TYPES.TIME,
        },
        dateTo: {
          type: FIELD_TYPES.DATE,
        },
        timeTo: {
          type: FIELD_TYPES.TIME,
        },
      },
    },
    cinema: {
      properties: {
        dateFrom: {
          type: FIELD_TYPES.DATE,
          validate: ['required'],
          description: 'Data',
        },
        timeFrom: {
          type: FIELD_TYPES.TIME,
          description: 'Godzina',
        },
      },
    },
  },
}

export default schema
