import React from 'react'
import clsx from 'clsx'
import { Chip, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  chip: {
    color: '#eee',
    cursor: 'pointer',
  },
  complete: {
    backgroundColor: theme.palette.success.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  pending: {
    backgroundColor: theme.palette.info.main,
  },
  waiting: {
    backgroundColor: '#555',
  },
}))

export const StateCell = ({ resource }) => {
  const result = resource.state.value

  const classes = useStyles()

  return (
    <Tooltip title={resource.errorMessage || 'Brak dodatkowych informacji'}>
      <Chip
        label={resource.state.title}
        className={clsx(
          classes.chip,
          result === 'complete'
            ? classes.complete
            : result === 'error'
            ? classes.error
            : result === 'pending'
            ? classes.pending
            : classes.waiting
        )}
      />
    </Tooltip>
  )
}
