import _ from 'lodash'
import { constants } from './constants'

export const reducer = (state, action) => {
  switch (action.type) {
    case constants.SET_VALUE:
      const valueObject = action.payload.name.includes('.')
        ? getValueObject(
            action.payload.name.split('.').reverse(),
            action.payload.value
          )
        : { [action.payload.name]: action.payload.value }

      return {
        ...state,
        values: { ..._.merge(state.values, valueObject) },
        renderError: action.payload.name.includes('.')
          ? state.renderError
          : {
              ...state.renderError,
              [action.payload.name]:
                action.payload.setRenderError ||
                state.renderError[action.payload.name],
            },
      }
    case constants.SET_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.name]: action.payload.error,
        },
        isInvalid:
          Object.keys(state.errors)
            .filter(key => key !== action.payload.name)
            .reduce(
              (isInvalid, key) => !!state.errors[key] || isInvalid,
              false
            ) || !!action.payload.error,
      }
    case constants.SET_ADDITIONAL:
      return {
        ...state,
        additional: {
          ...state.additional,
          ...action.payload.additional,
        },
      }
    case constants.RENDER_ERROR:
      return {
        ...state,
        renderError: Object.assign(
          {},
          ...Object.keys(state.renderError).map(name => ({ [name]: true }))
        ),
      }
    case constants.SUBMIT:
      return {
        ...state,
        isSubmitted: true,
      }
    case constants.PROCESS:
      return {
        ...state,
        isProcessing: action.payload,
      }
    case constants.SUCCESS:
      return {
        ...state,
        isProcessing: false,
        values:
          action.payload.method === 'PUT'
            ? _.merge(state.values, action.payload.resource)
            : state.values,
        compareValues: null,
      }
    case constants.FAILURE:
      return {
        ...state,
        isProcessing: false,
        errors: {
          ...state.errors,
          ...action.payload.errors,
        },
      }
    case constants.SET_COMPARE_RESOURCE:
      return {
        ...state,
        isProcessing: false,
        compareValues: action.payload,
      }
    case constants.REMOVE_COMPARE_RESOURCE:
      return {
        ...state,
        values: {
          ...state.values,
          updatedAt: state.compareValues.updatedAt,
        },
        compareValues: null,
      }
    default:
      return state
  }
}

const getValueObject = (name, value) => {
  if (name.length < 1) {
    return value
  }

  const current = name.pop()

  const object = {
    [current]: getValueObject(name, value),
  }

  return object
}
