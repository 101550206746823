import { LANGS_IRI } from '_lib/langs'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import exhibitSchema from '_schema/exhibit'
import ageRestrictionsSchema from '_schema/_enums/ageRestriction'

/** importable */
const schema = {
  endpoint: '/api/artists',
  access: 'artist',
  resource: {
    definition: '#Artist',
    properties: artist => ({
      mainExhibit: {
        type: FIELD_TYPES.RESOURCE,
        description: 'Wybrana praca artysty',
        titleAccessor: resource =>
          `${resource.title} (${resource.objectNumber})`,
        endpoint: `${exhibitSchema.endpoint}?artists.uuid=${artist.uuid}&pagination=false`,
      },
      ageRestriction: {
        description: 'Ograniczenie wiekowe',
        type: FIELD_TYPES.RESOURCE,
        titleAccessor: 'title',
        endpoint: ageRestrictionsSchema.endpoint,
      },
    }),
  },
  front: {
    [LANGS_IRI.PL]: '/artysci/:slug',
    [LANGS_IRI.EN]: '/en/artists/:slug',
  },
}

export default schema
