import React, { useCallback, useState, useMemo } from 'react'
import DOMPurify from 'dompurify'
import { Link } from 'react-router-dom'
import {
  AppBar,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@material-ui/core'
import { ExpandMoreOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { useDispatch } from 'react-redux'
import { commonConstants } from '_constants'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { ManualNextRevalidatorButton } from 'components/ManualNextRevalidatorButton'
import { NotFound } from 'pages/NotFound'
import { LANGS_IRI } from '_lib/langs'
import { translate } from '_helpers/translate'
import { Media } from 'pages/Resources/components/Media'
import schema from '_schema/artist'
import resourceRoutes from 'pages/Resources/routes'
import moment from 'moment'
import { Form } from 'components/form'
import { boolean } from '_helpers/boolean'

const useStyles = makeStyles({
  root: {
    fontSize: 16,
  },
  margin_top: {
    marginTop: 20,
  },
  accordion_wrapper: {
    width: '100%',
  },
  data: {
    '&>*': {
      marginTop: 5,
    },
  },
})

const TabPanel = ({ children, value, index, other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <Box p={3}>{children}</Box>}
  </div>
)

export const View = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const [state, setState] = useResourceState()

  const dispatch = useDispatch()
  const dispatchOnFetch = useCallback(
    resource =>
      dispatch({
        type: commonConstants.SET_CURRENT_RESOURCE,
        payload: { resource },
      }),
    [dispatch]
  )

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false,
    false,
    setState.isNotEditable,
    dispatchOnFetch
  )

  const [tab, setTab] = useState(0)

  const handleChange = (e, newValue) => {
    setTab(newValue)
  }

  const classes = useStyles()

  const frontRoutes = useMemo(
    () => ({
      PL: `${process.env.REACT_APP_NEXT_ENTRYPOINT}${schema.front[
        LANGS_IRI.PL
      ].replace(':slug', resource?.slug)}`,
      EN: `${process.env.REACT_APP_NEXT_ENTRYPOINT}${schema.front[
        LANGS_IRI.EN
      ].replace(':slug', resource?.slug)}`,
    }),
    [resource]
  )

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <div className={classes.root}>
        <ManualNextRevalidatorButton type="artist" uuid={resource.uuid} />
        <h2>Display Name [Always display]: {resource.title}</h2>
        <h4>Source ID: {resource.importId}</h4>
        <div
          style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
        >
          {resource.resourcesPreviewData.map(resource => (
            <div
              key={resource.uuid}
              style={{
                marginRight: 15,
                marginBottom: 15,
                backgroundColor: '#eee',
                padding: 5,
              }}
            >
              <div>
                <div style={{ marginBottom: 10 }}>{resource.title}</div>
                <Media resource={resource} />
                <div>{resource.section.title}</div>
              </div>
              <Link
                to={resourceRoutes().view.path.replace(':id', resource.uuid)}
              >
                {translate('Zobacz w panelu')}
              </Link>
            </div>
          ))}
        </div>
        <div style={{ marginTop: 15 }}>
          <b>Linki frontowe:</b>
          <ul>
            <li>
              PL:{' '}
              <a
                href={frontRoutes.PL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {frontRoutes.PL}
              </a>
            </li>
            <li>
              EN:{' '}
              <a
                href={frontRoutes.EN}
                target="_blank"
                rel="noopener noreferrer"
              >
                {frontRoutes.EN}
              </a>
            </li>
          </ul>
        </div>
        <div style={{ marginTop: 15 }}>
          <b>Last Updated:</b>{' '}
          {resource.importUpdatedAt
            ? moment(resource.importUpdatedAt).format('DD.MM.YYYY HH:mm:ss')
            : null}
        </div>
        <div style={{ marginTop: 15 }}>
          <b>Name Original:</b> {resource.originalTitle}
        </div>
        <div>
          <b>First name:</b> {resource.firstName}
        </div>
        <div>
          <b>Last name:</b> {resource.lastName}
        </div>
        <div style={{ marginTop: 15 }}>
          <b>Birth Date:</b> {resource.birthDate}
        </div>
        <div>
          <b>Birthplace:</b> {resource.birthPlace}
        </div>
        <div style={{ marginTop: 15 }}>
          <b>Death Date:</b> {resource.deathDate}
        </div>
        <div>
          <b>Deathplace:</b> {resource.deathPlace}
        </div>
        <div style={{ marginTop: '15px' }}>
          <Form
            url={resource['@id']}
            method="PUT"
            properties={schema.resource.properties(resource)}
            resource={{
              mainExhibit: resource.mainExhibit,
              ageRestriction: resource.ageRestriction,
            }}
            width={800}
            fetchCompareResource={boolean(
              process.env.REACT_APP_FORM_FETCH_COMPARE_RESOURCE
            )}
            //handleSuccess={handleSuccess}
            showSubmitAndStayButton={false}
            showCancelButton={false}
            definitions={definitions}
            disabled={false}
          />
        </div>
        <Accordion className={classes.margin_top}>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="translations-content"
            id="translations-header"
          >
            Tłumaczenia
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.accordion_wrapper}>
              <AppBar position="static">
                <Tabs value={tab} onChange={handleChange} aria-label="tabs">
                  <Tab label="PL" />
                  <Tab label="EN" />
                </Tabs>
              </AppBar>
              <TabPanel value={tab} index={0}>
                <div className={classes.data}>
                  <div>
                    <strong>Name Polish:</strong>{' '}
                    <ul>{resource.translations[LANGS_IRI.PL].title}</ul>
                  </div>
                  <div>
                    <strong>Biography PL:</strong>{' '}
                    <ul>
                      {resource.translations[LANGS_IRI.PL].biographies.map(
                        (biography, index) => (
                          <li
                            key={index}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(biography),
                            }}
                          />
                        )
                      )}
                    </ul>
                  </div>
                  <div>
                    <strong>Audiodescription PL:</strong>{' '}
                    <ul>
                      {resource.translations[
                        LANGS_IRI.PL
                      ].audioDescriptions.map((description, index) => (
                        <li
                          key={index}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(description),
                          }}
                        />
                      ))}
                    </ul>
                  </div>
                  <div>
                    <strong>Transcription PL:</strong>{' '}
                    <ul>
                      {resource.translations[LANGS_IRI.PL].transcriptions.map(
                        (transcription, index) => (
                          <li
                            key={index}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(transcription),
                            }}
                          />
                        )
                      )}
                    </ul>
                  </div>
                  <div>
                    <strong>
                      External media link Film about artist _film link PL:
                    </strong>{' '}
                    <ul>
                      {resource.translations[LANGS_IRI.PL].filmLinks.map(
                        (link, index) => (
                          <li key={index}>
                            {link ? (
                              <a
                                href={link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {link}
                              </a>
                            ) : null}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <div className={classes.data}>
                  <div>
                    <strong>Name English:</strong>{' '}
                    <ul>{resource.translations[LANGS_IRI.EN].title}</ul>
                  </div>
                  <div>
                    <strong>Biography EN:</strong>{' '}
                    <ul>
                      {resource.translations[LANGS_IRI.EN].biographies.map(
                        (biography, index) => (
                          <li
                            key={index}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(biography),
                            }}
                          />
                        )
                      )}
                    </ul>
                  </div>
                  <div>
                    <strong>Audiodescription EN:</strong>{' '}
                    <ul>
                      {resource.translations[
                        LANGS_IRI.EN
                      ].audioDescriptions.map((description, index) => (
                        <li
                          key={index}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(description),
                          }}
                        />
                      ))}
                    </ul>
                  </div>
                  <div>
                    <strong>Transcription EN:</strong>{' '}
                    <ul>
                      {resource.translations[LANGS_IRI.EN].transcriptions.map(
                        (transcription, index) => (
                          <li
                            key={index}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(transcription),
                            }}
                          />
                        )
                      )}
                    </ul>
                  </div>
                  <div>
                    <strong>
                      External media link Film about artist _film link EN:
                    </strong>{' '}
                    <ul>
                      {resource.translations[LANGS_IRI.EN].filmLinks.map(
                        (link, index) => (
                          <li key={index}>
                            {link ? (
                              <a
                                href={link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {link}
                              </a>
                            ) : null}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </TabPanel>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </Paper>
  )
}
