import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import { LANGS_IRI } from '_lib/langs'

const ITEMS_ENDPOINT = '/api/<TYPE>_block_related_links'

export const publications = type => ({
  name: BLOCK_TYPES_IRI.PUBLICATIONS,
  label: 'Publikacje powiązane',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
      },
    },
    relatedLinks: {
      description: 'Publikacje',
      type: FIELD_TYPES.EMBEDDED_COLLECTION,
      endpoint: ITEMS_ENDPOINT.replace('<TYPE>', type),
      titleAccessor: resource =>
        resource?.publication?.translations[LANGS_IRI.PL]?.title ||
        '<Brak publikacji>',
      properties: {
        publication: {
          type: FIELD_TYPES.AUTOCOMPLETE,
          titleProperty: 'title',
          searchProperty: 'title',
          description: 'Publikacja',
          validate: ['required'],
          endpoint: '/api/publishing_house_publications',
        },
      },
    },
  },
})
