import React from 'react'
import { Route } from 'react-router-dom'
import { EventOutlined } from '@material-ui/icons'
import { Collection } from './Collection'
import { SchemableComponent } from 'components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { Collection as TermCollection } from './Terms/Collection'
import { New as TermNew } from './Terms/New'
import { Edit as TermEdit } from './Terms/Edit'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import schema from '_schema/event'

const PATH = '/events'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: PATH,
        exact: true,
        icon: EventOutlined,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        path: `${PATH}/new`,
        exact: true,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: `${PATH}/:id`,
        exact: true,
      },
      terms_index: {
        subtitle: 'Harmonogram',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={TermCollection}
            {...props}
          />
        ),
        path: `${PATH}/:pid/terms`,
        exact: true,
      },
      terms_new: {
        subtitle: 'Dodaj termin',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={TermNew}
            {...props}
          />
        ),
        path: `${PATH}/:pid/terms/new`,
        exact: true,
      },
      terms_edit: {
        subtitle: 'Edytuj termin',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={TermEdit}
            {...props}
          />
        ),
        path: `${PATH}/:pid/terms/:id`,
        exact: true,
      },
    },
    {
      title: 'Wydarzenia',
      resourceAccess: schema.access,
      indexPath: PATH,
    }
  )

export default routes
