import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

export const events = () => ({
  name: BLOCK_TYPES_IRI.EVENTS,
  label: 'Wydarzenia',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
      },
    },
    events: {
      type: FIELD_TYPES.MULTIPLE_RESOURCE,
      description: 'Wydarzenia',
      titleAccessor: 'title',
      endpoint: `/api/events?pagination=false`,
    },
  },
})
