import React, { useState } from 'react'
import { Tooltip } from '@material-ui/core'
import { FileCopy } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { DialogLoader } from 'components/DialogLoader'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { translate } from '_helpers/translate'
import schema from '_schema/clonerQueueItem'

const useStyles = makeStyles(theme => ({
  withIcon: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: 'inherit',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  withoutIcon: {
    width: '100%',
    textDecoration: 'none',
    cursor: 'pointer',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  disabled: {
    color: theme.palette.disabled,
  },
}))

export const Clone = ({
  resource,
  endpoint = null,
  disabled = false,
  onSuccess = null,
  isIcon = false,
  adminTitle = null,
  adminUrl = null,
}) => {
  const [isFetching, setIsFetching] = useState(false)

  const classes = useStyles()
  const handleClick = () => {
    if (disabled) {
      return
    }

    setIsFetching(true)

    fetchDataHandleAuthError(
      endpoint || schema.endpoint,
      'POST',
      {
        body: JSON.stringify({
          iri: resource['@id'],
          ...(!endpoint
            ? {
                adminTitle,
                adminUrl,
              }
            : {}),
        }),
      },
      () => {
        setIsFetching(false)

        notification(
          'success',
          endpoint ? 'T_FORM_RECORD_CLONED' : 'Dodano do kolejki',
          'T_FORM_SUCCESS'
        )
        onSuccess && onSuccess()
      },
      error => {
        setIsFetching(false)

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  return (
    <>
      {disabled ? (
        isIcon ? (
          <FileCopy className={classes.disabled} disabled />
        ) : (
          <span className={classes.disabled}>
            {translate('T_GENERAL_CLONE')}
          </span>
        )
      ) : (
        <>
          <div
            onClick={handleClick}
            className={isIcon ? classes.withIcon : classes.withoutIcon}
          >
            {isIcon ? (
              <Tooltip title={translate('T_GENERAL_CLONE')}>
                <FileCopy />
              </Tooltip>
            ) : (
              translate('T_GENERAL_CLONE')
            )}
          </div>
        </>
      )}
      <DialogLoader
        isOpen={isFetching}
        title={
          endpoint ? translate('T_GENERAL_CLONING') : 'Dodaję do kolejki...'
        }
      />
    </>
  )
}
