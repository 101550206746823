import React from 'react'
import { Form } from 'pages'
import { Sidebar } from 'components/resource/sidebar'
import schema from '_schema/eventCycle'
import routes from './routes'
import { BlockCollection } from '../../components/block'
import { ManualNextRevalidatorButton } from '../../components/ManualNextRevalidatorButton'
import { PreviewButton } from 'components/PreviewButton'
import { LANGS_IRI } from '_lib/langs'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`
  
  const classes = useStyles()

  return (
    <Form
      definitions={definitions}
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor="title"
      SidebarComponent={Sidebar}
      width={600}
      header={resource => (
        <div className={classes.header}>
          <PreviewButton
            resource={resource}
            endpoint={`${schema.endpoint}/preview_token`}
            link={`${schema.resource.properties.translations.properties.slug.routes[
              LANGS_IRI.PL
            ].replace(':slug', resource.translations[LANGS_IRI.PL]?.slug)}`}
          />
          <div style={{ marginBottom: 10 }}>
            <ManualNextRevalidatorButton
              type="event_cycle"
              uuid={resource.uuid}
            />
          </div>
        </div>
      )}
    >
      <BlockCollection
        endpoint={schema.subresources.block.endpoint}
        parentIri={iri}
        definitions={definitions}
        definitionSchema={definitions[schema.subresources.block.definition]}
        types={schema.subresources.block.types}
        statable={true}
        key={`${iri}-blocks`}
      />
    </Form>
  )
}
