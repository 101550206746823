import _ from 'lodash'
import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import mediaSchema from '_schema/media'
import { LANGS_IRI } from '_lib/langs'

const ACCORDIONS_ENDPOINT = '/api/<TYPE>_block_accordions'
const ACCORDIONS_ENTRIES_ENDPOINT = '/api/<TYPE>_block_accordion_entries'
const ACCORDIONS_ENTRIES_FILES_ENDPOINT =
  '/api/<TYPE>_block_accordion_entry_files'

const ACCORDION_ENTRY_REF = '#<TYPE>BlockAccordionEntry'
const ACCORDION_ENTRY_FILE_REF = '#<TYPE>BlockAccordionEntryFile'

export const accordions = type => ({
  name: BLOCK_TYPES_IRI.ACCORDIONS,
  label: 'Akordeon',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
        text: {
          description: 'Tekst',
          type: FIELD_TYPES.TINYMCE,
        },
      },
    },
    isAccordionsTitleAlignedLeft: {
      description: 'Tytuł po lewej stronie',
      type: FIELD_TYPES.BOOLEAN,
    },
    isAccordionsExpanded: {
      description: 'Accordiony zawsze rozwinięte',
      type: FIELD_TYPES.BOOLEAN,
    },
    accordionsTitleIcon: {
      type: FIELD_TYPES.IMAGE,
      description: 'Ikona przy tytule',
      endpoint: mediaSchema.endpoint,
      validate: ['maxSize'],
    },
    accordions: {
      description: 'Accordiony',
      type: FIELD_TYPES.EMBEDDED_COLLECTION,
      endpoint: ACCORDIONS_ENDPOINT.replace('<TYPE>', type),
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            title: {
              description: 'Tytuł',
              type: FIELD_TYPES.STRING,
            },
            text: {
              description: 'Tekst',
              type: FIELD_TYPES.TINYMCE,
            },
          },
        },
        entries: {
          description: 'Sekcje w accordionie',
          type: FIELD_TYPES.EMBEDDED_COLLECTION,
          endpoint: ACCORDIONS_ENTRIES_ENDPOINT.replace('<TYPE>', type),
          $ref: ACCORDION_ENTRY_REF.replace(
            '<TYPE>',
            `${_.camelCase(type)
              .charAt(0)
              .toUpperCase()}${_.camelCase(type).slice(1)}`
          ),
          titleAccessor: () => 'Sekcja',
          properties: {
            translations: {
              type: FIELD_TYPES.TRANSLATION,
              properties: {
                text: {
                  description: 'Tekst',
                  type: FIELD_TYPES.TINYMCE,
                },
                ctaTitle: {
                  description: 'CTA: tytuł',
                  type: FIELD_TYPES.STRING,
                },
                ctaLink: {
                  description: 'CTA: link',
                  type: FIELD_TYPES.STRING,
                },
              },
            },
            video: {
              description: 'Video',
              type: FIELD_TYPES.FILE,
              endpoint: mediaSchema.endpoint,
              accept: 'video/*',
              restrictManagerMimeType: 'video/',
              validate: ['maxSize'],
            },
            files: {
              description: 'Pliki sekcji accordionowego',
              type: FIELD_TYPES.EMBEDDED_COLLECTION,
              endpoint: ACCORDIONS_ENTRIES_FILES_ENDPOINT.replace(
                '<TYPE>',
                type
              ),
              $ref: ACCORDION_ENTRY_FILE_REF.replace(
                '<TYPE>',
                `${_.camelCase(type)
                  .charAt(0)
                  .toUpperCase()}${_.camelCase(type).slice(1)}`
              ),
              titleAccessor: `translations.${LANGS_IRI.PL}.title`,
              properties: {
                translations: {
                  type: FIELD_TYPES.TRANSLATION,
                  properties: {
                    title: {
                      description: 'Tytuł',
                      type: FIELD_TYPES.STRING,
                    },
                    media: {
                      description: 'Plik',
                      type: FIELD_TYPES.FILE,
                      endpoint: mediaSchema.endpoint,
                      validate: ['maxSize'],
                      handleFileManagerMetadata: (setValue, media) => {
                        setValue('injectTranslations', {
                          [LANGS_IRI.PL]: {
                            title: media.translations[LANGS_IRI.PL].title,
                          },
                          [LANGS_IRI.EN]: {
                            title: media.translations[LANGS_IRI.EN].title,
                          },
                        })
                      },
                    },
                  },
                },
                media: {
                  description: 'Plik',
                  type: FIELD_TYPES.FILE,
                  endpoint: mediaSchema.endpoint,
                  validate: ['maxSize'],
                  handleFileManagerMetadata: (setValue, media) => {
                    setValue('injectTranslations', {
                      [LANGS_IRI.PL]: {
                        title: media.translations[LANGS_IRI.PL].title,
                      },
                      [LANGS_IRI.EN]: {
                        title: media.translations[LANGS_IRI.EN].title,
                      },
                    })
                  },
                },
              },
            },
          },
        },
      },
    },
  },
})
