import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import logoGroupSizesSchema from '_schema/_enums/logoGroupSize'
import mediaSchema from '_schema/media'
import { LANGS_IRI } from '_lib/langs'

const GROUPS_ENDPOINT = '/api/<TYPE>_block_logo_groups'
const ITEMS_ENDPOINT = '/api/<TYPE>_block_logo_group_items'
const ITEMS_THUMBS_ENDPOINT = '/api/<TYPE>_block_logo_group_item_thumbs'
const ITEM_TRANSLATION_THUMBS_ENDPOINT =
  '/api/<TYPE>_block_logo_group_item_translation_thumbs'

export const logoGroups = type => ({
  name: BLOCK_TYPES_IRI.LOGO_GROUPS,
  label: 'Logotypy partnerów',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
      },
    },
    groups: {
      description: 'Grupy',
      type: FIELD_TYPES.EMBEDDED_COLLECTION,
      endpoint: GROUPS_ENDPOINT.replace('<TYPE>', type),
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            title: {
              description: 'Tytuł',
              type: FIELD_TYPES.STRING,
            },
          },
        },
        size: {
          description: 'Rozmiar',
          type: FIELD_TYPES.RESOURCE,
          titleAccessor: 'title',
          endpoint: logoGroupSizesSchema.endpoint,
          validate: ['required'],
        },
        items: {
          description: 'Loga',
          type: FIELD_TYPES.EMBEDDED_COLLECTION,
          endpoint: ITEMS_ENDPOINT.replace('<TYPE>', type),
          properties: {
            translations: {
              type: FIELD_TYPES.TRANSLATION,
              properties: {
                title: {
                  description: 'Tytuł',
                  type: FIELD_TYPES.STRING,
                },
                alt: {
                  description: 'Opis alternatywny',
                  type: FIELD_TYPES.STRING,
                },
                link: {
                  description: 'Link',
                  type: FIELD_TYPES.STRING,
                },
                media: {
                  type: FIELD_TYPES.IMAGE,
                  description: 'Logo tłumaczone',
                  endpoint: mediaSchema.endpoint,
                  thumbs: {
                    endpoint: ITEM_TRANSLATION_THUMBS_ENDPOINT.replace(
                      '<TYPE>',
                      type
                    ),
                  },
                  validate: ['maxSize'],
                  handleFileManagerMetadata: (setValue, media) => {
                    setValue('injectTranslations', {
                      [LANGS_IRI.PL]: {
                        title: media.translations[LANGS_IRI.PL].title,
                        alt: media.translations[LANGS_IRI.PL].alt,
                      },
                      [LANGS_IRI.EN]: {
                        title: media.translations[LANGS_IRI.EN].title,
                        alt: media.translations[LANGS_IRI.EN].alt,
                      },
                    })
                  },
                },
              },
            },
            media: {
              type: FIELD_TYPES.IMAGE,
              description: 'Logo',
              endpoint: mediaSchema.endpoint,
              thumbs: {
                endpoint: ITEMS_THUMBS_ENDPOINT.replace('<TYPE>', type),
              },
              validate: ['maxSize'],
              handleFileManagerMetadata: (setValue, media) => {
                setValue('injectTranslations', {
                  [LANGS_IRI.PL]: {
                    title: media.translations[LANGS_IRI.PL].title,
                    alt: media.translations[LANGS_IRI.PL].alt,
                  },
                  [LANGS_IRI.EN]: {
                    title: media.translations[LANGS_IRI.EN].title,
                    alt: media.translations[LANGS_IRI.EN].alt,
                  },
                })
              },
            },
          },
        },
      },
    },
  },
})
