import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'

const PATH = '/api/revalidate/:type/:uuid'

const useStyles = makeStyles({
  manual_import: {
    marginTop: 0,
  },
})

export const ManualNextRevalidatorButton = ({ type, uuid }) => {
  const handleRevalidate = () => {
    fetchDataHandleAuthError(
      PATH.replace(':type', type).replace(':uuid', uuid),
      'PUT',
      {},
      () => {
        notification('success', 'Cache wyczyszczony', 'T_FORM_SUCCESS')
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const classes = useStyles()

  return (
    <div className={classes.manual_import}>
      <Button variant="contained" color="secondary" onClick={handleRevalidate}>
        [DEV] Wyczyść cache frontu (next)
      </Button>
    </div>
  )
}
