import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

export const pressArticles = () => ({
  name: BLOCK_TYPES_IRI.PRESS_ARTICLES,
  label: 'Prasa',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
      },
    },
    pressArticles: {
      type: FIELD_TYPES.MULTIPLE_RESOURCE,
      description: 'Artykuły prasowe',
      titleAccessor: 'title',
      endpoint: `/api/press_articles?pagination=false`,
    },
  },
})
