import React, { useState } from 'react'
import moment from 'moment'
import { CustomButton } from 'components/CustomButton'
import { makeStyles } from '@material-ui/styles'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  inner_root: {
    '&>*': {
      display: 'block',
      marginBottom: 10,
    },
  },
  button: {
    display: 'flex',
  },
})

export const PreviewButton = ({
  resource: originalResource,
  endpoint,
  link,
}) => {
  const [resource, setResource] = useState(originalResource)
  const [isFetching, setIsFetching] = useState(false)

  const token = resource.previewToken
  const validTill = resource.previewTokenValidTill

  const now = moment()
  const till = moment(validTill)

  const tokenizedLink =
    token && now < till
      ? `${process.env.REACT_APP_NEXT_ENTRYPOINT}${link}?token=${token}`
      : null

  const handleGenerateToken = () => {
    if (isFetching) {
      return
    }

    setIsFetching(true)

    fetchDataHandleAuthError(
      endpoint,
      'POST',
      {
        body: JSON.stringify({
          iri: resource['@id'],
        }),
      },
      response => {
        setIsFetching(false)

        setResource(response)

        notification('success', 'Token wygenerowany', 'T_FORM_SUCCESS')
      },
      error => {
        setIsFetching(false)

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const defaultClasses = useStyles()

  return (
    <div className={defaultClasses.root}>
      <div className={defaultClasses.inner_root}>
        {tokenizedLink && (
          <a href={tokenizedLink} target="_blank" rel="noopener noreferrer">
            {tokenizedLink}
          </a>
        )}
        <div className={defaultClasses.button}>
          <CustomButton onClick={handleGenerateToken} disabled={isFetching}>
            <span>Generuj nowy link do podglądu</span>
          </CustomButton>
        </div>
      </div>
    </div>
  )
}
