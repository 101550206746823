import React, { useCallback, useState } from 'react'
import {
  AppBar,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@material-ui/core'
import { ExpandMoreOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { Media } from './components/Media'
import { useDispatch } from 'react-redux'
import { commonConstants } from '_constants'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { ManualNextRevalidatorButton } from 'components/ManualNextRevalidatorButton'
import { NotFound } from 'pages/NotFound'
import { LANGS_IRI } from '_lib/langs'
import schema from '_schema/resource'
import moment from 'moment'

const useStyles = makeStyles({
  root: {
    fontSize: 16,
  },
  margin_top: {
    marginTop: 20,
  },
  accordion_wrapper: {
    width: '100%',
  },
  data: {
    '&>*': {
      marginTop: 5,
    },
  },
})

const TabPanel = ({ children, value, index, other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <Box p={3}>{children}</Box>}
  </div>
)

export const View = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const [state, setState] = useResourceState()

  const dispatch = useDispatch()
  const dispatchOnFetch = useCallback(
    resource =>
      dispatch({
        type: commonConstants.SET_CURRENT_RESOURCE,
        payload: { resource },
      }),
    [dispatch]
  )

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false,
    false,
    setState.isNotEditable,
    dispatchOnFetch
  )

  const [tab, setTab] = useState(0)

  const handleChange = (e, newValue) => {
    setTab(newValue)
  }

  const classes = useStyles()

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <div className={classes.root}>
        <ManualNextRevalidatorButton type="resource" uuid={resource.uuid} />
        <h2>{resource.translations[LANGS_IRI.PL].title}</h2>
        <h4>Source ID: {resource.importId}</h4>
        <Media resource={resource} />
        <div style={{ marginTop: 15 }}>
          <b>Section:</b> {resource.section.title}
        </div>
        <div style={{ marginTop: 15 }}>
          <b>Rendition Number:</b> {resource.renditionNumber}
        </div>
        <div>
          <b>Media View:</b> {resource.view}
        </div>
        <div>
          <b>Media Language version:</b>{' '}
          {resource.language.value === 'pl' ? 'polski' : 'angielski'}
        </div>
        {resource.media?.originalName && (
          <div>
            <b>Original name:</b> {resource.media.originalName}
          </div>
        )}
        <div style={{ marginTop: 15 }}>
          <b>Last updated:</b>{' '}
          {resource.importUpdatedAt
            ? moment(resource.importUpdatedAt).format('DD.MM.YYYY HH:mm:ss')
            : null}
        </div>
        <div>
          <b>Ostatni import zdjęcia:</b>{' '}
          {resource.imageUpdatedAt
            ? moment(resource.imageUpdatedAt).format('DD.MM.YYYY HH:mm:ss')
            : 'brak'}
        </div>
        <Accordion className={classes.margin_top}>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="translations-content"
            id="translations-header"
          >
            Tłumaczenia
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.accordion_wrapper}>
              <AppBar position="static">
                <Tabs value={tab} onChange={handleChange} aria-label="tabs">
                  <Tab label="PL" />
                  <Tab label="EN" />
                </Tabs>
              </AppBar>
              <TabPanel value={tab} index={0}>
                <div className={classes.data}>
                  <div>
                    <strong>Headline PL:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].headline}
                  </div>
                  <div>
                    <strong>Public Caption PL:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].caption}
                  </div>
                  <div>
                    <strong>Description PL:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].description}
                  </div>
                  <div>
                    <strong>Copyright PL:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].copyright}
                  </div>
                  <div>
                    <strong>
                      External Media Link _audiodeskrypcja audio link PL:
                    </strong>{' '}
                    {resource.translations[LANGS_IRI.PL].audioDescriptionLink}
                  </div>
                  <div>
                    <strong>External Media Link _PJM film link PL:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].pjmVideoLink}
                  </div>
                  <div>
                    <strong>External Streaming Link PL:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].streamingLink}
                  </div>
                  <div>
                    <strong>Alt Text PL:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].alt}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <div className={classes.data}>
                  <div>
                    <strong>Headline EN:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].headline}
                  </div>
                  <div>
                    <strong>Public Caption EN:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].caption}
                  </div>
                  <div>
                    <strong>Description EN:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].description}
                  </div>
                  <div>
                    <strong>Copyright EN:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].copyright}
                  </div>
                  <div>
                    <strong>
                      External Media Link _audiodeskrypcja audio link EN:
                    </strong>{' '}
                    {resource.translations[LANGS_IRI.EN].audioDescriptionLink}
                  </div>
                  <div>
                    <strong>External Media Link _PJM film link EN:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].pjmVideoLink}
                  </div>
                  <div>
                    <strong>External Streaming Link EN:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].streamingLink}
                  </div>
                  <div>
                    <strong>Alt Text EN:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].alt}
                  </div>
                </div>
              </TabPanel>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </Paper>
  )
}
