export const translations = {
  pl: {
    /* Inside application code */
    T_GENERAL_OPERATIONS: 'Operacje',
    T_GENERAL_EDIT: 'Edytuj',
    T_GENERAL_NEW: 'Dodaj',
    T_GENERAL_CLONE: 'Klonuj',
    T_GENERAL_CLONING: 'Klonuję...',
    T_GENERAL_VERSION_CREATE: 'Archiwizuj',
    T_GENERAL_VERSION_CREATING: 'Archiwizuję...',
    T_GENERAL_VERSION_RESTORE: 'Przywróć wersję',
    T_GENERAL_VERSION_RESTORING: 'Przywracam wersję...',
    T_GENERAL_VERSION_CREATED_AT: 'Wersja z dnia',
    T_GENERAL_VIEW: 'Pokaż',
    T_GENERAL_DELETE: 'Usuń',
    T_GENERAL_DELETE_CONFIRM: 'Czy napewno usunąć',
    T_GENERAL_DELETED: 'Rekord usunięty',
    T_GENERAL_CREATED_AT: 'Utworzono',
    T_GENERAL_UPDATED_AT: 'Ostatnia aktualizacja',
    T_GENERAL_CANCEL: 'Anuluj',
    T_GENERAL_CONFIRM: 'Potwierdź',
    T_GENERAL_STATUS_CHANGED: 'Zmieniono status',
    T_GENERAL_SAVE: 'Zapisz',
    T_GENERAL_SAVE_TO_KEEP_CHANGES: 'Zapisz aby zachować zmiany',
    T_GENERAL_SEARCH: 'Szukaj',
    T_GENERAL_ALL: 'Wszystkie',
    T_GENERAL_REFRESH: 'Odswież',
    T_GENERAL_TOGGLE_MENU: 'Przełącznik menu',
    T_GENERAL_RESULT_SUCCESS: 'Udane',
    T_GENERAL_RESULT_FAILURE: 'Nieudane',
    T_GENERAL_NO: 'Nr',
    T_GENERAL_OPERATION_DATE: 'Data operacji',
    T_GENERAL_MODULE: 'Moduł',
    T_GENERAL_RECORD: 'Rekord',
    T_GENERAL_OPERATION: 'Operacja',
    T_GENERAL_SHOW_ALL: 'Pokaż wszystkie',
    T_GENERAL_REQUIRED: 'Wymagany',
    T_GENERAL_DATE: 'Data',
    T_GENERAL_EMPTY: 'Brak',
    T_GENERAL_TITLE: 'Tytuł',
    T_GENERAL_INVALID_DATE: 'Niepoprawny format',
    T_GENERAL_INVALID_MIN_DATE: 'Data nie może być mniejsza niż 01.01.1990',
    T_GENERAL_INVALID_MAX_DATE: 'Data nie może być większa niż 01.01.2100',
    T_GENERAL_DESCRIPTION: 'Krótki opis',
    T_GENERAL_DESCRIPTION_HINT: 'Wyświetlany na detalu',
    T_GENERAL_LEAD: 'Lead',
    T_GENERAL_LEAD_HINT: 'Wyświetlany na liście',
    T_GENERAL_PUBLICATION: 'Publikacja',
    T_GENERAL_CODE: 'Kod',
    T_GENERAL_TIME: 'Czas',
    T_GENERAL_ADMIN: 'Administrator',
    T_GENERAL_RECORD_LIST: 'Lista',
    T_GENERAL_RECORD_NEW: 'Dodaj',
    T_GENERAL_RECORD_NEW_BUTTON: 'Dodaj nowy rekord',
    T_GENERAL_RECORD_EDIT: 'Edycja',
    T_GENERAL_RECORD_SHOW: 'Pokaż',
    T_GENERAL_RECORD_PREVIEW: 'Podgląd',
    T_GENERAL_RECORD_VERSION_COLLECTION: 'Poprzednie wersje',
    T_GENERAL_RECORD_VERSION_VIEW: 'Podgląd',
    T_GENERAL_RECORD_VIEW: 'Wyświetl',
    T_GENERAL_ITEMS_EMPTY: 'Brak elementów',
    T_GENERAL_BLOCK: 'Bloki',
    T_GENERAL_BLOCK_TYPE_TEXT: 'Blok tekstowy',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_PHOTO: 'Blok tekstowy ze zdjęciem',
    T_GENERAL_CHOOSE: 'Wybierz',
    T_GENERAL_MISSING_TITLE: '<Brak tytułu>',
    T_GENERAL_SUPER_ADMIN: 'Super Admin',
    T_GENERAL_TRANSLATION: 'Tłumaczenie',
    T_GENERAL_MANUAL_ORDER: 'Manualna kolejność',
    T_GENERAL_MANUAL_ORDER_PAGE_DROPZONE_PREVIOUS:
      'Upuść tu żeby przenieść na poprzednią stronę',
    T_GENERAL_MANUAL_ORDER_PAGE_DROPZONE_NEXT:
      'Upuść tu żeby przenieść na następną stronę',
    T_GENERAL_DRAG_HANDLE: 'Przytrzymaj i przeciągnij, aby zmienić kolejność',
    T_GENERAL_UPLOAD: 'Upload pliku',
    T_GENERAL_UPLOAD_MULTIPLE: 'Upload plików',
    T_GENERAL_PHOTO: 'Zdjęcie',
    T_GENERAL_IMAGE_ADD: 'Dodaj zdjęcie',
    T_GENERAL_IMAGE_CHANGE: 'Wymień zdjęcie',
    T_GENERAL_IMAGE_REMOVE: 'Usuń zdjęcie',
    T_GENERAL_IMAGE_REMOVE_CONFIRM: 'Czy napewno usunąć zdjęcie?',
    T_GENERAL_PHOTO_THUMBS: 'Kadry',
    T_GENERAL_PHOTO_THUMBS_EMPTY: 'Brak kadrów',
    T_GENERAL_PHOTO_THUMBS_SAVE: 'Zapisz kadr',
    T_GENERAL_PHOTO_THUMBS_RESET: 'Resetuj kadr',
    T_GENERAL_PHOTO_THUMBS_CHANGE_FILE: 'Zmień plik dla kadru',
    T_GENERAL_EXPAND_ON: 'zwiń wszystkie',
    T_GENERAL_EXPAND_OFF: 'rozwiń wszystkie',
    T_GENERAL_EXPAND_BLOCKS: 'bloki',
    T_GENERAL_BLOCK_COLLECTION_TITLE: 'Komponenty',
    T_GENERAL_ALT: 'Opis alternatywny',
    T_GENERAL_FILE: 'Plik',
    T_GENERAL_FILE_ADD: 'Dodaj plik',
    T_GENERAL_FILE_CHANGE: 'Wymień plik',
    T_GENERAL_FILE_REMOVE: 'Usuń plik',
    T_GENERAL_FILE_REMOVE_CONFIRM: 'Czy napewno usunąć plik?',
    T_GENERAL_NAME: 'Nazwa',
    T_GENERAL_SEND: 'Wyślij',
    T_GENERAL_CHOSEN_FILE: 'Wybrany plik',
    T_GENERAL_CHOSEN_FILES: 'Wybrane pliki',
    T_GENERAL_NONE: 'brak',
    T_GENERAL_YOUTUBE_CODE: 'Kod filmu youtube',
    T_GENERAL_VIDEO_MASK: 'Maska filmu',
    T_GENERAL_MEDIA_PHOTO: 'Zdjęcie',
    T_GENERAL_MEDIA_YOUTUBE: 'Film youtube',
    T_GENERAL_MASS_SELECT_ACTION: 'Co zrobić z zaznaczonymi rekordami',
    T_GENERAL_MASS_SELECT_CHOOSE: 'Wybierz',
    T_GENERAL_MASS_SELECT_PUBLISH: 'Publikuj',
    T_GENERAL_MASS_SELECT_UNPUBLISH: 'Odpublikuj',
    T_GENERAL_ENABLE: 'Włącz',
    T_GENERAL_DISABLE: 'Wyłącz',
    T_GENERAL_OPENFORM_ADMIN_GROUP_IS_OPENFORM: '[OF] Prawa openform',
    T_GENERAL_OPENFORM_PAGE_IDNAME: '[OF] Id name',
    T_GENERAL_OPENFORM_PAGE_IS_STATABLE: '[OF] Publikowalna',
    T_GENERAL_OPENFORM_PAGE_IS_EDITABLE: '[OF] Edytowalna',
    T_GENERAL_OPENFORM_PAGE_IS_DELETABLE: '[OF] Usuwalna',
    T_GENERAL_OPENFORM_PAGE_IS_SUBPAGEABLE: '[OF] Podstrony',
    T_GENERAL_SAVE_FORM_TO_EDIT: 'Zapisz formularz, aby edytować',
    T_GENERAL_PASSWORD_WAS_RESETTED:
      'Twoje hasło zostało zresetowane przez administratora. Ustaw nowe hasło, a następnie przeloguj się, aby odzyskać dostęp.',
    T_GENERAL_EDIT_DESCRIPTION: 'edytuj opis',
    T_GENERAL_BACK_TO_LIST: 'Wróć na listę',
    T_GENERAL_NEXT: 'Następny',
    T_GENERAL_PREVIOUS: 'Poprzedni',
    T_GENERAL_FULL_SCREEN: 'Full screen',
    T_GENERAL_LOGIN: 'Zaloguj',
    T_GENERAL_LOGOUT: 'Wyloguj',
    T_GENERAL_REMEMBER_ME: 'Zapamiętaj mnie',
    T_GENERAL_FORGOT_PASSWORD: 'Zapomniałem hasła',
    T_GENERAL_FORGOT_PASSWORD_TITLE: 'Zapomniałem hasła',
    T_GENERAL_FORGOT_PASSWORD_TYPE_EMAIL: 'Twój adres e-mail',
    T_GENERAL_FORGOT_PASSWORD_SEND_LINK: 'Wyślij link',
    T_GENERAL_FORGOT_PASSWORD_VALIDATION_EMAIL: 'Niepoprawny adres e-mail',
    T_GENERAL_FORGOT_PASSWORD_LINK_SENT:
      'Link wysłany. Sprawdź swój adres e-mail',
    T_GENERAL_NEW_PASSWORD_TITLE: 'Nadaj nowe hasło',
    T_GENERAL_NEW_PASSWORD_TYPE_NEW: 'Wpisz nowe hasło',
    T_GENERAL_NEW_PASSWORD_RETYPE_NEW: 'Powtórz nowe hasło',
    T_GENERAL_NEW_PASSWORD_CHANGE: 'Zmień hasło',
    T_GENERAL_NEW_PASSWORD_RESETTED: 'Hasło zostało zmienione',
    T_GENERAL_BACK_TO_LOGIN: 'Powrót do logowania',
    T_GENERAL_MAP: 'Mapa',
    T_GENERAL_MAP_HINT:
      'Wpisz pełną lokalizację lub częściową i wybierz z listy. Marker na mapie zostanie automatycznie ustawiony',
    T_GENERAL_MAP_INPUT: 'Wpisz lokalizację',
    T_GENERAL_NORMAL_VIEW: 'Powrót do poprzedniego widoku',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_CHOSEN: 'Dodajesz % plików',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_INFO: 'Nadaj wszystkim dodawanym:',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_UPLOADING: 'Dodaję',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_UPLOADING_FROM: 'z',
    T_GENERAL_CONTEXTUAL_HINT: 'Pomoc kontekstowa',
    T_GENERAL_YOU_ARE_LOGGED_AS: 'Jesteś zalogowany jako',
    T_GENERAL_PREVIEW: 'Podgląd',
    T_GENERAL_MASS_UPLOAD_DRAG_AND_DROP:
      'Przeciągnij i upuść lub kliknij, żeby wybrać',
    T_GENERAL_PLANNED_PUBLISH_DATE_TIME: 'Planowana publikacja',
    T_GENERAL_PUBLISH: 'Publikuj',
    T_GENERAL_UNPUBLISH: 'Odpublikuj',
    T_GENERAL_PUBLISHED: 'Opublikowana',
    T_GENERAL_DRAFT: 'Szkic',
    T_GENERAL_VERSION: 'Wersja',
    T_GENERAL_DOWNLOAD_YOUTUBE_THUMBNAIL: 'Pobierz zaślepkę z youtube',
    T_LOGIN_DATE: 'Data logowania',
    T_TABLE_FIRST_PAGE: 'Pierwsza strona',
    T_TABLE_LAST_PAGE: 'Ostatnia strona',
    T_TABLE_RESET: 'Wyczyść ustawienia tabeli',
    T_TABLE_NO_RESULTS: 'Brak wyników',
    T_MODULE_DASHBOARD: 'Dashboard',
    T_MODULE_SETTINGS: 'Ustawienia',
    T_MODULE_PROFILE: 'Profil',
    T_MODULE_TRANSLATOR_ENTRIES: 'Lista tłumaczeń',
    T_MODULE_TRANSLATOR_ENTRIES_NEW_BUTTON: 'Dodaj nowe tłumaczenie',
    T_MODULE_ADMINS: 'Lista administratorów',
    T_MODULE_ADMINS_NEW_BUTTON: 'Dodaj nowego administratora',
    T_MODULE_ADMIN_GROUPS: 'Lista grup administratorskich',
    T_MODULE_ADMIN_GROUPS_NEW_BUTTON: 'Dodaj nową grupę administratorską',
    T_MODULE_ARTICLES: 'Lista artykułów',
    T_MODULE_ARTICLES_NEW_BUTTON: 'Dodaj nowy artykuł',
    T_MODULE_PAGES: 'Lista stron',
    T_MODULE_PAGES_NEW_BUTTON: 'Dodaj nową stronę',
    T_MODULE_PAGES_EDIT_STRUCTURE: 'Edycja struktury',
    T_MODULE_PAGES_SHOW_ALL_CHILDREN: 'Rozwiń wszystko',
    T_MODULE_PAGES_HIDE_ALL_CHILDREN: 'Zwiń wszystko',
    T_MODULE_PAGES_ADD_SUBPAGE: 'Dodaj podstronę',
    T_MODULE_PAGES_SUBPAGE_UNDER_PREVIOUS_PAGE:
      'Przenieś jako podstronę strony wyżej',
    T_MENU_REGISTRIES: 'Rejestry',
    T_REQUEST_METHOD_GET: 'Wyświetlenie',
    T_REQUEST_METHOD_PUT: 'Edycja',
    T_REQUEST_METHOD_POST: 'Utworzenie',
    T_REQUEST_METHOD_DELETE: 'Usunięcie',
    T_FORM_PROFILE_TITLE: 'Dane',
    T_FORM_CHANGE_PASSWORD_TITLE: 'Zmiana hasła',
    T_FORM_FIELD_EMAIL: 'E-mail',
    T_FORM_FIELD_PASSWORD: 'Hasło',
    T_FORM_FIELD_NEW_PASSWORD: 'Nowe hasło',
    T_FORM_FIELD_NEW_PASSWORD_REPEAT: 'Powtórz nowe hasło',
    T_FORM_FIELD_HINT_ENTER_NEW_PASSWORD: 'Podaj nowe hasło',
    T_FORM_FIELD_HINT_REPEAT_NEW_PASSWORD: 'Powtórz nowe hasło',
    T_FORM_FIELD_RESOURCE_ACCESS: 'Dostęp do modułów',
    T_FORM_FIELD_BOOLEAN_ON: 'Tak',
    T_FORM_FIELD_BOOLEAN_OFF: 'Nie',
    T_FORM_INCORRECT: 'Niepoprawny formularz',
    T_FORM_SUCCESS: 'Zapisano',
    T_FORM_RECORD_UPDATED: 'Rekord zaktualizowany',
    T_FORM_RECORD_CREATED: 'Rekord dodany',
    T_FORM_RECORD_CLONED: 'Rekord sklonowany',
    T_FORM_RECORD_MULTIPLE_CREATED: 'Rekordy dodane',
    T_FORM_RECORD_MULTIPLE_UPDATED: 'Rekordy zaktaulizowane',
    T_FORM_RECORD_UPLOADED: 'Rekord wgrany',
    T_FORM_RECORD_VERSION_CREATED: 'Wersja rekordu utworzona',
    T_FORM_RECORD_VERSION_RESTORED: 'Wersja rekordu przywrócona',
    T_FORM_CONTEXTUAL_HINTS_ON: 'Pomoc kontekstowa włączona',
    T_FORM_CONTEXTUAL_HINTS_OFF: 'Pomoc kontekstowa wyłączona',
    T_FORM_SAVE: 'Zapisz',
    T_FORM_SAVE_AND_STAY: 'Zapisz i pozostań',
    T_FORM_ACCEPT: 'Akceptuj',
    T_FORM_CANCEL: 'Anuluj',
    T_FORM_RECORD_CHANGED:
      'Rekord został zmieniony przez innego administratora. Sprawdź zmiany i zaakceptuj.',
    T_VALIDATION_REQUIRED: 'Pole wymagane',
    T_VALIDATION_EMAIL: 'Niepoprawny e-mail',
    T_VALIDATION_PHONE: 'Niepoprawny numer telefonu',
    T_VALIDATION_PASSWORD:
      'Hasło musi składać się z min. 8 znaków (duża litera, mała litera, cyfra, znak specjalny)',
    T_VALIDATION_PASSWORDS_DO_NOT_MATCH: 'Hasła nie zgadzają się',
    T_VALIDATION_LENGTH: 'To pole musi się składać z min. % znaków',
    T_VALIDATION_MAX_UPLOAD_SIZE: 'Maksymalny rozmiar pliku to %',
    T_VALIDATION_MAX_UPLOAD_MULTIPLE_SIZE:
      'Maksymalny rozmiar wszystkich plików to %',
    T_VALIDATION_MIN_VALUE: 'Minimalna wartość to %',
    T_VALIDATION_MAX_VALUE: 'Maksymalna wartość to %',
    T_404_TITLE: 'Strona nie istnieje',
    T_404_TEXT:
      'Wygląda na to, że kliknąłeś niedziałający link lub wprowadziłeś adres URL, który nie istnieje na tej witrynie.',
    T_404_BACK: 'Powrót na stronę główną',
    T_SETTINGS_MODE_LIGHT: 'Przejdź na tryb jasny',
    T_SETTINGS_MODE_DARK: 'Przejdź na tryb ciemny',
    T_FRONT_PREVIEW_ERROR_TITLE: 'Błąd',
    T_FRONT_PREVIEW_ERROR_TEXT: 'Błąd przy pobieraniu strony frontowej',
    T_GENERAL_FRONT_ROUTE: 'Link',
    /* Thumbs */
    'T_THUMB_API/PAGE_BLOCK_THUMBS_CROPPED_EXAMPLE': 'Kadr przykładowy',
    /* Returned by API: modules */
    Administrators: 'Administratorzy',
    'Administrator Groups': 'Grupy administratorskie',
    'Registry entries': 'Rejestr zmian',
    'Login entries': 'Historia logowania',
    Pages: 'Struktura stron',
    'Page blocks': 'Bloki strony',
    'Page block thumbs': 'Kadry bloku strony',
    'Translator entries': 'Tłumaczenia',
    Articles: 'Artykuły',
    'Page block sections': 'Sekcje bloku strony',
    'Page block section thumbs': 'Kadry sekcji bloku strony',
    Message: 'Informacja',
    Success: 'Status',
    /* Returned by API: fields */
    'Name and surname': 'Imię i nazwisko',
    Group: 'Grupa',
    'Is enabled': 'Aktywny',
    Title: 'Tytuł',
    Username: 'Nazwa użytkownika',
    'Origin ip': 'IP',
    Result: 'Status',
    'Is super admin': 'Prawa super admina',
    'Title of article': 'Tytuł artykułu',
    'Text of article': 'Zawartość artykułu',
    'Show in main menu': 'Pokaż w menu głównym',
    Text: 'Tekst główny',
    'Meta title': '[SEO] Meta tytuł',
    'Meta description': '[SEO] Meta opis',
    'Meta keywords': '[SEO] Meta słowa kluczowe',
    Slug: 'Slug (generowany automatycznie z tytułu)',
    'Code of translator entry': 'Kod tłumaczenia',
    'Title of translator entry': 'Tłumaczenie',
    Question: 'Pytanie',
    Answer: 'Odpowiedź',
    Name: 'Nazwa',
    'Type of element': 'Typ elementu',
    'Is required': 'Wymagany do uzupełnienia',
    Description: 'Opis',
    Subtitle: 'Podtytuł',
    Quotation: 'Cytat',
    'Button title': 'Tytuł linka',
    'Button link': 'Link',
    Sections: 'Sekcje',
    'Text before media': 'Tekst przed zdjęciem',
    'Text after media': 'Tekst po zdjęciu',
    'Item type': 'Typ medium',
    'Media position': 'Pozycja zdjęcia',
    'Gallery type': 'Typ galerii',
    'Photo count': 'Zdjęć',
    'Video count': 'Filmów',
    'Media type': 'Typ medium',
    Photographer: 'Fotograf',
    'Code of entry': 'Kod',
    'Administration group': 'Grupa',
    'Is home wider': 'Szerszy kafel na SG',
    Config: 'Konfiguracja',
    Type: 'Typ',
    Types: 'Typy',
    'Tile left header': 'Kafel: lewy header',
    'Tile right header': 'Kafel: prawy header',
    'Ticket cta title': 'Tytuł przycisku kup bilet',
    'Admin: url': 'Link',
    'Admin: title': 'Link',
    State: 'Stan',
    /* Returned by API: general */
    'Your current password is incorrect.': 'Podałeś niepoprawne hasło',
    'Bad credentials.': 'Niepoprawne dane',
    Error: 'Błąd',
    TypeError: 'Błąd',
    'An error occurred': 'Błąd',
    'Not Found': 'Rekord nie odnaleziony',
    'Syntax error': 'Błąd składni. Skontaktuj się z administratorem',
    'This value is already used.': 'Ta wartość jest już używana',
    'This value should not be null.': 'Pole wymagane',
    Forbidden: 'Brak dostępu do tego zasobu',
    Unauthorized: 'Dostęp zablokowany',
    'Internal Server Error': 'Wystąpił błąd. Skontaktuj się z administratorem',
    'TypeError: Failed to fetch':
      'Błąd połączenia z serwerem. Skontaktuj się z administratorem',
    'Password is common.': 'Wybrałeś znane hasło',
    'You have already used that password.': 'Hasło zostało już wcześniej użyte',
    'Login blocked for 15 mins': 'Logowanie zablokowane na 15 minut',
    'Circular parentage detected. Current entity or current entity descendants cannot be set as a parent to current entity.':
      'Niepoprawna zmiana rodzica. Nie można przenieść strony w dół aktualnego drzewa.',
    'Parent page is not subpageable.':
      'Niepoprawna zmiana rodzica. Rodzic nie może posiadać podstron.',
    'Nesting level exceeded.':
      'Niepoprawna zmiana rodzica. Osiągnięto maksymalny poziom zagnieżdżenia stron.',
    'Youtube thumbnail does not exist.': 'Zaślepka nie istnieje',
    'Admin does not exist.': 'Administrator nie istnieje.',
    'Reset password link has been already generated.':
      'Link do zresetowania hasła został już wygenerowany.',
    'Incorrect hash.': 'Niepoprawny link.',
    'Link expired.': 'Wygasły link.',
    'Reset password blocked for 15 mins.':
      'Resetowanie hasła zablokowane na 15 minut',
    'Depth exceeded.': 'Głębokość przekroczona',
  },
}
