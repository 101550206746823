import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import mediaSchema from '_schema/media'
import { LANGS_IRI } from '_lib/langs'

const ITEMS_ENDPOINT = '/api/<TYPE>_block_images'
const ITEMS_THUMBS_ENDPOINT = '/api/<TYPE>_block_image_thumbs'

export const imageGallery = type => ({
  name: BLOCK_TYPES_IRI.IMAGE_GALLERY,
  label: 'Galeria zdjęć',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
      },
    },
    isImageTextHidden: {
      description: 'Opisy zdjęć ukryte',
      type: FIELD_TYPES.BOOLEAN,
    },
    images: {
      description: 'Zdjęcia',
      type: FIELD_TYPES.EMBEDDED_COLLECTION,
      endpoint: ITEMS_ENDPOINT.replace('<TYPE>', type),
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            title: {
              description: 'Tytuł',
              type: FIELD_TYPES.STRING,
            },
            subtitle: {
              description: 'Podpis',
              type: FIELD_TYPES.STRING,
            },
            text: {
              description: 'Opis',
              type: FIELD_TYPES.TINYMCE,
            },
            alt: {
              description: 'Opis alternatywny',
              type: FIELD_TYPES.STRING,
            },
          },
        },
        media: {
          type: FIELD_TYPES.IMAGE,
          description: 'Zdjęcie',
          endpoint: mediaSchema.endpoint,
          thumbs: {
            endpoint: ITEMS_THUMBS_ENDPOINT.replace('<TYPE>', type),
          },
          validate: ['maxSize'],
          handleFileManagerMetadata: (setValue, media) => {
            setValue('injectTranslations', {
              [LANGS_IRI.PL]: {
                title: media.translations[LANGS_IRI.PL].title,
                text: media.translations[LANGS_IRI.PL].description,
                alt: media.translations[LANGS_IRI.PL].alt,
              },
              [LANGS_IRI.EN]: {
                title: media.translations[LANGS_IRI.EN].title,
                text: media.translations[LANGS_IRI.EN].description,
                alt: media.translations[LANGS_IRI.EN].alt,
              },
            })
          },
        },
      },
    },
  },
})
