import React, { useMemo, useCallback } from 'react'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import {
  Table,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  Checkbox,
} from '@material-ui/core'
import {
  BodySkeleton,
  TableLoader,
  TableReset,
  FilterBar,
  ManuallyOrderableTableBody,
  ManuallyOrderableTableRow,
  ManualOrderDragHandle,
  MassSelectChoice,
  MassSelectCheckbox,
  useCollectionTableStyles as useOriginalCollectionTableStyles,
} from 'components/table'
import { collectionTablePropTypes } from './CollectionTable.propTypes'
import { useCollectionTableStyles } from './CollectionTable.styles'
import { DefaultCell } from 'components/table/cells/DefaultCell'
import {
  prepareColumns,
  prepareFilters,
  prepareSorters,
  addCustomFilters,
} from 'components/table/_helpers'
import { performManualOrderChange } from 'pages/Pages/_helpers/performManualOrderChange'
import { TitleCell } from './cells/TitleCell'
import { operations } from './columns/operations'
import { useCollectionFetch } from 'pages/Pages/_helpers/useCollectionFetch'
import { applyProperties } from '_helpers/applyProperties'
import { translate } from '_helpers/translate'
import { isObjectEmpty } from '_helpers/isObjectEmpty'
import { constants, reducer } from './_state'
import { LANGS_IRI } from '_lib/langs'
import schema from '_schema/page'

export const CollectionTable = ({
  parameters = [],
  definitionSchema,
  endpoint,
  customResourceSchema = {},
  defaultSorters,
  defaultFilters,
  customFilters = [],
  resetable = false,
  filterable = false,
  selectable = [],
  isRowSelectable = true,
  storeCollectionId = null,
  editPath = null,
  children = null,
  classes = {},
}) => {
  const orderable = useMemo(() => true, [])
  const columnsAmount = useMemo(() => 3, [])
  const columns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_TITLE'),
        accessor: `translations.${LANGS_IRI.PL}.title`,
        width: '79%',
        Cell: TitleCell,
      },
      {
        ...operations(
          `translations.${LANGS_IRI.PL}.title`,
          true,
          true,
          true,
          true,
          true
        ),
        width: '15%',
      },
    ],
    []
  )

  const preparedColumns = useMemo(
    () => prepareColumns(columns, definitionSchema.properties),
    [columns, definitionSchema.properties]
  )

  const preparedFilters = useMemo(
    () => prepareFilters(defaultFilters, preparedColumns, parameters),
    [defaultFilters, preparedColumns, parameters]
  )

  const filters = useMemo(
    () => addCustomFilters(customFilters, preparedFilters, parameters),
    [customFilters, preparedFilters, parameters]
  )

  const sorters = useMemo(
    () =>
      prepareSorters(orderable, defaultSorters, preparedColumns, parameters),
    [orderable, defaultSorters, preparedColumns, parameters]
  )

  const [state, dispatch] = useCollectionFetch(
    reducer,
    endpoint,
    filters,
    sorters,
    storeCollectionId
  )

  const resourceMergedProperties = useMemo(
    () =>
      applyProperties(
        definitionSchema.properties,
        customResourceSchema.properties
      ),
    [definitionSchema.properties, customResourceSchema.properties]
  )

  const handleFilters = useCallback(
    filters =>
      dispatch({ type: constants.HANDLE_COLUMN_FILTERS, payload: { filters } }),
    [dispatch]
  )

  const storeDispatch = useDispatch()

  const handleManualOrderChange = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex) {
        return
      }

      performManualOrderChange(
        state.data.items,
        oldIndex,
        newIndex,
        dispatch,
        storeDispatch
      )
    },
    [state.data.items, dispatch, storeDispatch]
  )

  const handleReset = useCallback(() => {
    dispatch({ type: constants.RESET })
  }, [dispatch])

  const setSorters = useCallback(
    sorters => dispatch({ type: constants.SET_SORTERS, payload: { sorters } }),
    [dispatch]
  )

  const setFilters = useCallback(
    filters => dispatch({ type: constants.SET_FILTERS, payload: { filters } }),
    [dispatch]
  )

  const handleSingleSelect = useCallback(
    row => () =>
      dispatch({ type: constants.SET_SINGLE_SELECT, payload: { row } }),
    [dispatch]
  )

  const handleToggleAll = useCallback(() => {
    if (state.init || state.isFetching) {
      return
    }

    dispatch({ type: constants.TOGGLE_ALL })
  }, [state.init, state.isFetching, dispatch])

  const defaultClasses = useOriginalCollectionTableStyles()
  const customClasses = useCollectionTableStyles()

  const isSelectable = !!(selectable && selectable.length)

  return (
    <TableContainer
      className={clsx(defaultClasses.container, classes.container)}
    >
      <TableLoader show={!state.init && state.isFetching} />
      <div className={clsx(defaultClasses.toolbar, classes.toolbar)}>
        {resetable && (
          <TableReset
            disabled={state.isInit || state.isFetching}
            handleReset={handleReset}
          />
        )}
        {filterable && !isObjectEmpty(filters) && (
          <FilterBar
            filters={state.config.filters}
            disabled={state.isInit || state.isFetching}
            handleFilters={handleFilters}
          />
        )}
      </div>
      {children &&
        children({
          init: state.init,
          isFetching: state.isFetching,
          sorters: state.config.sorters,
          filters: state.config.filters,
          setSorters,
          setFilters,
        })}
      {isSelectable && (
        <MassSelectChoice
          selectable={selectable}
          tableState={state}
          tableStateDispatch={dispatch}
          classes={{
            container: classes.massSelectContainer,
            title: classes.massSelectContainerTitle,
          }}
        />
      )}
      <Table size="small">
        <TableHead className={defaultClasses.tHead}>
          <TableRow>
            {isSelectable && (
              <TableCell key="header-mass" style={{ width: '6%' }}>
                <div
                  className={clsx(
                    defaultClasses.massHeadColumn,
                    defaultClasses.massHeadColumnIsOrderable,
                    classes.massHeadColumn
                  )}
                >
                  <MassSelectCheckbox
                    tableState={state}
                    tableStateDispatch={dispatch}
                    isRowSelectable={isRowSelectable}
                  />
                </div>
              </TableCell>
            )}
            <TableCell key="header-main" colSpan={2} style={{ width: '94%' }}>
              <div className={customClasses.header}>
                {process.env.REACT_APP_PAGE_MAX_DEPTH > 1 && (
                  <>
                    <span
                      className={clsx(
                        customClasses.toggle,
                        state.data.toggledAll && customClasses.toggleActive,
                        (state.init || state.isFetching) &&
                          customClasses.toggleDisabled
                      )}
                      onClick={state.data.toggledAll ? handleToggleAll : null}
                    >
                      {translate('T_MODULE_PAGES_SHOW_ALL_CHILDREN')}
                    </span>
                    <span
                      className={clsx(
                        customClasses.toggle,
                        !state.data.toggledAll && customClasses.toggleActive,
                        (state.init || state.isFetching) &&
                          customClasses.toggleDisabled
                      )}
                      onClick={!state.data.toggledAll ? handleToggleAll : null}
                    >
                      {translate('T_MODULE_PAGES_HIDE_ALL_CHILDREN')}
                    </span>
                  </>
                )}
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <ManuallyOrderableTableBody
          onSortEnd={handleManualOrderChange}
          helperClass={clsx(defaultClasses.draggedRow, classes.draggedRow)}
          useDragHandle={true}
        >
          {state.init ? (
            <BodySkeleton
              rows={state.config.perPage}
              columns={columnsAmount - 1}
            />
          ) : state.data.items.length > 0 ? (
            state.data.items.map((item, i) => (
              <ManuallyOrderableTableRow
                className={clsx(defaultClasses.row, classes.row)}
                index={i}
                key={`${item.uuid}-${Date.now()}`}
              >
                <TableCell key="header-mass" style={{ width: '6%' }}>
                  <div
                    className={clsx(
                      defaultClasses.massColumn,
                      classes.massColumn
                    )}
                  >
                    {state.config.sorters.ord.order && (
                      <ManualOrderDragHandle />
                    )}
                    {isSelectable &&
                      (typeof isRowSelectable === 'boolean'
                        ? isRowSelectable
                        : isRowSelectable(item)) && (
                        <Checkbox
                          checked={!!state.data.selected[i].value}
                          onChange={handleSingleSelect(i)}
                          name="mass"
                          color="secondary"
                        />
                      )}
                  </div>
                </TableCell>
                {preparedColumns.map((column, j) => {
                  const CellComponent = column.Cell || DefaultCell

                  return (
                    <TableCell
                      className={clsx(defaultClasses.cell, classes.cell)}
                      key={`column-${j}`}
                      style={{ width: column.width }}
                    >
                      <CellComponent
                        resource={item}
                        previousResource={
                          i > 0 ? state.data.items[i - 1] : null
                        }
                        accessor={column.accessor}
                        propertySchema={
                          resourceMergedProperties?.[column.accessor]
                        }
                        tableState={state}
                        tableStateDispatch={dispatch}
                        editPath={editPath}
                        orderable={true}
                        collectionTitle="Struktura stron"
                      />
                    </TableCell>
                  )
                })}
              </ManuallyOrderableTableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={columnsAmount}
                className={clsx(
                  defaultClasses.emptyResults,
                  classes.emptyResults
                )}
              >
                {translate('T_TABLE_NO_RESULTS')}
              </TableCell>
            </TableRow>
          )}
        </ManuallyOrderableTableBody>
      </Table>
    </TableContainer>
  )
}

CollectionTable.propTypes = collectionTablePropTypes
