import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/newsletter_categories',
  access: 'newsletterCategory',
  resource: {
    definition: '#NewsletterCategory',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {
            type: FIELD_TYPES.STRING,
            description: 'Label/tytuł',
          },
          description: {
            type: FIELD_TYPES.TEXTAREA,
            description: 'Opis',
          },
          hashId: {
            type: FIELD_TYPES.STRING,
            description: '[MAILERLITE] Id listy',
          },
        },
      },
      // listlang: {
      //   type: FIELD_TYPES.CHOICE,
      //   choices: {
      //     pl: 'polska',
      //     en: 'angielska',
      //   },
      //   label: 'Wersja językowa newslettera, zależy od wybranej przez użytkownika wersji językowej serwisu, podczas zapisu do newslettera',
      //   // validate: ['required'],
      // },
    },
  },
}

export default schema
