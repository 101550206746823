import React from 'react'
import { useHistory } from 'react-router-dom'
import { IconButton } from '@material-ui/core'
import { History } from '@material-ui/icons'
import { prop } from '_helpers/prop'
import routes from 'pages/Events/routes'

export const TermCell = ({ resource, accessor }) => {
  const history = useHistory()

  const redirectToSchedule = () => {
    history.push(routes().terms_index.path.replace(':pid', resource.uuid))
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <IconButton onClick={redirectToSchedule}>
        <History />
      </IconButton>
      <span>{prop(resource, accessor)}</span>
    </div>
  )
}
