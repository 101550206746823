import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
  link: {
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
})

export const LinkCell = ({ resource }) => {
  const history = useHistory()

  const onClick = () => {
    if (resource.state.value === 'complete') {
      history.push(resource.adminUrl.replace(':id', resource.cloneUuid))
    }
  }

  const classes = useStyles()

  return (
    <div
      onClick={onClick}
      className={resource.state.value === 'complete' ? classes.link : null}
    >
      {resource.adminTitle}
    </div>
  )
}
