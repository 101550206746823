import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { NotFound } from 'pages'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { operations } from './table/_columns/operations'
import { IsAvailableCell } from 'pages/Events/Terms/table/cells/IsAvailableCell'
import { commonConstants } from '_constants'
import schema from '_schema/eventTerm'
import parentSchema from '_schema/event'
import routes from 'pages/Events/routes'

export const Collection = ({ schema: definitions, ...rest }) => {
  const { match, location } = rest

  const dispatch = useDispatch()

  const onFetchSuccess = useCallback(
    resource => {
      dispatch({
        type: commonConstants.ADD_CUSTOM_BREADCRUMBS,
        payload: {
          path: location.pathname,
          breadcrumbs: [
            {
              path: routes().index.path,
              title: 'Wydarzenia',
            },
            {
              path: routes().edit.path.replace(':id', match.params.pid),
              title: resource.title,
            },
            {
              path: location.pathname,
              title: routes().terms_index.subtitle,
            },
          ],
        },
      })
    },
    [dispatch, location.pathname, match.params.pid]
  )

  const parentIri = useMemo(
    () => `${parentSchema.endpoint}/${match.params.pid}`,
    [match.params.pid]
  )

  const [state, setState] = useResourceState()

  const { resource: parentResource, isFetching, fetchError } = state

  useResourceFetch(
    parentIri,
    parentResource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false,
    null,
    null,
    onFetchSuccess
  )

  const itemColumns = useMemo(
    () => [
      {
        header: 'Data',
        accessor: 'date',
        width: '60%',
      },
      {
        header: 'Dostępny',
        accessor: 'isAvailable',
        width: '15%',
        Cell: IsAvailableCell,
      },
      {
        header: 'Liczba miejsc',
        accessor: 'availablePlaces',
        width: '15%',
      },
      {
        ...operations(
          'dateFrom',
          resource => !resource.iksorisId,
          resource => !resource.iksorisId
        ),
        width: '10%',
      },
    ],
    []
  )

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <CollectionHeader
        buttonTitle="Dodaj nowy termin"
        buttonPath={routes().terms_new.path.replace(':pid', match.params.pid)}
      >
        Harmonogram wydarzenia: {parentResource?.title}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={`${schema.endpoint}`}
        definition={schema.resource.definition}
        storeCollectionId={`${parentSchema.endpoint}?iri[parent]=${parentIri}`}
        columns={itemColumns}
        customResourceSchema={schema.resource}
        defaultFilters={{
          'iri[parent]': parentIri,
        }}
        parameters={[
          {
            name: 'iri[parent]',
            in: 'query',
            type: 'string',
            value: false,
          },
          {
            name: 'order[dateFrom]',
            in: 'query',
            type: 'string',
            value: false,
          },
          {
            name: 'order[dateTo]',
            in: 'query',
            type: 'string',
            value: false,
          },
        ]}
        defaultSorters={{ dateFrom: 'desc', dateTo: 'desc' }}
        orderable={false}
        numerable={false}
        isRowLinkable={true}
        editPath={routes().terms_edit.path.replace(':pid', match.params.pid)}
        autoWidth={false}
        paginable={false}
      />
    </Paper>
  )
}
