import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import mediaSchema from '_schema/media'
import mediaPositionsSchema from '_schema/_enums/mediaPosition'
import mediaSizesSchema from '_schema/_enums/mediaSize'
import { LANGS_IRI } from '_lib/langs'

const THUMBS_ENDPOINT = '/api/<TYPE>_block_thumbs'

export const textWithPhoto = type => ({
  name: BLOCK_TYPES_IRI.TEXT_WITH_PHOTO,
  label: 'Baner',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
        subtext: {
          description: 'Subtytuł',
          type: FIELD_TYPES.TEXTAREA,
        },
        text: {
          description: 'Tekst',
          type: FIELD_TYPES.TINYMCE,
        },
        mediaTitle: {
          description: 'Zdjęcie: tytuł',
          type: FIELD_TYPES.STRING,
        },
        mediaSubtitle: {
          description: 'Zdjęcie: podtytuł',
          type: FIELD_TYPES.STRING,
        },
        mediaText: {
          description: 'Zdjęcie: tekst',
          type: FIELD_TYPES.TINYMCE,
        },
        ctaTitle: {
          description: 'CTA: tytuł',
          type: FIELD_TYPES.STRING,
        },
        ctaLink: {
          description: 'CTA: link',
          type: FIELD_TYPES.STRING,
        },
        alt: {
          description: 'Opis alternatywny',
          type: FIELD_TYPES.STRING,
        },
      },
    },
    media: {
      type: FIELD_TYPES.IMAGE,
      description: 'T_GENERAL_PHOTO',
      endpoint: mediaSchema.endpoint,
      thumbs: {
        endpoint: THUMBS_ENDPOINT.replace('<TYPE>', type),
      },
      validate: ['maxSize'],
      handleFileManagerMetadata: (setValue, media) => {
        setValue('injectTranslations', {
          [LANGS_IRI.PL]: {
            title: media.translations[LANGS_IRI.PL].title,
            text: media.translations[LANGS_IRI.PL].description,
            alt: media.translations[LANGS_IRI.PL].alt,
          },
          [LANGS_IRI.EN]: {
            title: media.translations[LANGS_IRI.EN].title,
            text: media.translations[LANGS_IRI.EN].description,
            alt: media.translations[LANGS_IRI.EN].alt,
          },
        })
      },
    },
    mediaPosition: {
      type: FIELD_TYPES.RESOURCE,
      description: 'Pozycja zdjęcia',
      titleAccessor: 'title',
      endpoint: `${mediaPositionsSchema.endpoint}?block=text_with_photo`,
      validate: ['required'],
    },
    mediaSize: {
      type: FIELD_TYPES.RESOURCE,
      description: 'Rozmiar zdjęcia',
      titleAccessor: 'title',
      endpoint: mediaSizesSchema.endpoint,
      validate: ['required'],
    },
  },
})
