import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import mediaSchema from '_schema/media'

const schema = {
  endpoint: '/api/config',
  access: 'config',
  resource: {
    definition: '#Config',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          contactAddress: { type: FIELD_TYPES.TEXTAREA },
          shopLink: {},
          metaTitle: {},
          metaDescription: {
            type: FIELD_TYPES.TEXTAREA,
          },
          metaKeywords: {
            type: FIELD_TYPES.TEXTAREA,
          },
        },
      },
      isMuseumOpen: {},
      mondayTimeFrom: { type: FIELD_TYPES.TIME },
      mondayTimeTo: { type: FIELD_TYPES.TIME },
      tuesdayTimeFrom: { type: FIELD_TYPES.TIME },
      tuesdayTimeTo: { type: FIELD_TYPES.TIME },
      wednesdayTimeFrom: { type: FIELD_TYPES.TIME },
      wednesdayTimeTo: { type: FIELD_TYPES.TIME },
      thursdayTimeFrom: { type: FIELD_TYPES.TIME },
      thursdayTimeTo: { type: FIELD_TYPES.TIME },
      fridayTimeFrom: { type: FIELD_TYPES.TIME },
      fridayTimeTo: { type: FIELD_TYPES.TIME },
      saturdayTimeFrom: { type: FIELD_TYPES.TIME },
      saturdayTimeTo: { type: FIELD_TYPES.TIME },
      sundayTimeFrom: { type: FIELD_TYPES.TIME },
      sundayTimeTo: { type: FIELD_TYPES.TIME },
      isCinemaOpen: {},
      cinemaMondayTimeFrom: { type: FIELD_TYPES.TIME },
      cinemaMondayTimeTo: { type: FIELD_TYPES.TIME },
      cinemaTuesdayTimeFrom: { type: FIELD_TYPES.TIME },
      cinemaTuesdayTimeTo: { type: FIELD_TYPES.TIME },
      cinemaWednesdayTimeFrom: { type: FIELD_TYPES.TIME },
      cinemaWednesdayTimeTo: { type: FIELD_TYPES.TIME },
      cinemaThursdayTimeFrom: { type: FIELD_TYPES.TIME },
      cinemaThursdayTimeTo: { type: FIELD_TYPES.TIME },
      cinemaFridayTimeFrom: { type: FIELD_TYPES.TIME },
      cinemaFridayTimeTo: { type: FIELD_TYPES.TIME },
      cinemaSaturdayTimeFrom: { type: FIELD_TYPES.TIME },
      cinemaSaturdayTimeTo: { type: FIELD_TYPES.TIME },
      cinemaSundayTimeFrom: { type: FIELD_TYPES.TIME },
      cinemaSundayTimeTo: { type: FIELD_TYPES.TIME },
      media: {
        type: FIELD_TYPES.IMAGE,
        description: 'Zdjęcie na facebooka',
        endpoint: mediaSchema.endpoint,
        validate: ['maxSize'],
      },
    },
  },
}

export default schema
