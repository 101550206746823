import { AuthError } from './authError'

export const handleResponse = response => {
  switch (response.status) {
    case 401:
      return Promise.reject(new AuthError())
    case 403:
      return response.text().then(text => {
        const data = text && JSON.parse(text)

        return Promise.reject({
          response: {
            title: !(data instanceof Array)
              ? data['hydra:title'] || data.title
              : response.status,
            detail: !(data instanceof Array)
              ? data['hydra:description'] || data.detail
              : response.statusText,
            violations: [],
          },
        })
      })
    default:
      break
  }

  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      return Promise.reject({
        response: {
          title: !(data instanceof Array)
            ? data['hydra:title'] || data.title
            : 'Error',
          detail: !(data instanceof Array)
            ? data['hydra:description'] || data.detail
            : response.statusText,
          violations: !(data instanceof Array) ? data.violations || [] : data,
        },
      })
    }

    return data
  })
}
