import { ResourceAccessType } from 'pages/AdminGroups/form/ResourceAccessType'

const schema = {
  endpoint: '/api/admin_groups',
  access: 'admin',
  resource: {
    definition: '#AdminGroup',
    properties: {
      title: {
        validate: ['required'],
      },
      isSuperAdmin: {},
      resourceAccess: {
        description: 'T_FORM_FIELD_RESOURCE_ACCESS',
        type: ResourceAccessType,
      },
    },
  },
}

export default schema
