import { LANGS_IRI } from '_lib/langs'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import _block from './_block'
import mediaSchema from './media'
import ageRestrictionsSchema from '_schema/_enums/ageRestriction'

const schema = {
  endpoint: '/api/magazines',
  access: 'magazine',
  resource: {
    definition: '#Magazine',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
          description: {
            type: FIELD_TYPES.TINYMCE,
          },
          descriptionList: {
            type: FIELD_TYPES.TEXTAREA,
            description: 'Opis na kafelku',
          },
          label: {
            description: 'Tekst obok daty',
          },
          signature: {
            description: 'Podpis',
          },
          showMoreLink: {
            description: 'Link przycisku "pokaż więcej"',
          },
          metaTitle: {},
          metaDescription: {
            type: FIELD_TYPES.TEXTAREA,
          },
          metaKeywords: {
            type: FIELD_TYPES.TEXTAREA,
          },
          slug: {
            type: FIELD_TYPES.SLUG,
            routes: {
              [LANGS_IRI.PL]: '/magazyn/:slug',
              [LANGS_IRI.EN]: '/magazine/:slug',
            },
          },
        },
      },
      media: {
        type: FIELD_TYPES.IMAGE,
        description: 'T_GENERAL_PHOTO',
        endpoint: mediaSchema.endpoint,
        thumbs: {
          endpoint: '/api/magazine_thumbs',
        },
        validate: ['maxSize'],
      },
      video: {
        type: FIELD_TYPES.FILE,
        description: 'Plik video',
        endpoint: mediaSchema.endpoint,
        restrictManagerMimeType: 'video/',
        accept: 'video/*',
        validate: ['maxSize'],
      },
      publishDate: {
        type: FIELD_TYPES.DATE,
        description: 'Data publikacji',
        validate: ['required'],
      },
      ageRestriction: {
        description: 'Ograniczenie wiekowe',
        type: FIELD_TYPES.RESOURCE,
        titleAccessor: 'title',
        endpoint: ageRestrictionsSchema.endpoint,
      },
      isShowMoreEnabled: {
        description: 'Włącz przycisk "pokaż więcej"',
        type: FIELD_TYPES.BOOLEAN,
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/magazine_blocks',
      definition: '#MagazineBlock',
      types: [..._block('magazine').types],
    },
  },
}

export default schema
