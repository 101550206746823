import React, { useState, useMemo, useCallback, useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  InputLabel,
  IconButton,
  Tooltip,
  FormHelperText,
} from '@material-ui/core'
import {
  CreateOutlined,
  PhotoSizeSelectLargeOutlined,
  DeleteForeverOutlined,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { UploadDialog } from './UploadDialog'
import { ThumbsDialog } from './ThumbsDialog'
import { RemoveDialog } from './RemoveDialog'
import { YoutubeThumbnail } from './YoutubeThumbnail'
import { ThumbCollection } from 'components/thumb/ThumbCollection'
import { translate } from '_helpers/translate'
import { validate } from '_helpers/validate'

const useStyles = makeStyles(theme => ({
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    position: 'relative',
    transform: 'none',
    display: 'inline-flex',
    color: theme.palette.text.secondary,
    marginRight: 5,
    verticalAlign: 'middle',
  },
  labelButton: {
    cursor: 'pointer',
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  disabledButton: {
    color: theme.palette.disabled,
  },
  imagePreview: {
    maxWidth: 500,
    maxHeight: 300,
    marginTop: 5,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  thumbs: {
    marginTop: 15,
  },
}))

export const FileType = ({
  name,
  type = 'file',
  label,
  hint = null,
  initialValue,
  value,
  error = false,
  renderError = false,
  endpoint,
  disabled = false,
  validators,
  setValue,
  setError,
  formUrl,
  formMethod,
  iri,
  thumbs = false,
  accept = null,
  ytCode = null,
  endpointYoutube = null,
  nodeRef = null,
  isManagerAvailable = true,
  restrictManagerMimeType = null,
  handleFileManagerMetadata = null,
  definitions,
  setFormValue,
  translationLang,
  classes = {},
}) => {
  const [state, setState] = useState({
    isUploadDialogOpen: false,
    isThumbsDialogOpen: false,
    isRemoveDialogOpen: false,
  })

  const handleToggle = type => () => {
    const fieldName = `is${type.charAt(0).toUpperCase()}${type.slice(
      1
    )}DialogOpen`

    setState(state => ({
      ...state,
      [fieldName]: !state[fieldName],
    }))
  }

  const handleChange = value => {
    setValue(name, value, true)
    validateField(value)
  }

  const setUpdatedAt = value => {
    setFormValue
      ? setFormValue('updatedAt', value)
      : setValue('updatedAt', value)
  }

  const uploadValidators = useMemo(
    () => validators && validators.filter(item => item !== 'required'),
    [validators]
  )

  const validateField = useCallback(
    value => {
      if (!validators || !validators.includes('required')) {
        setError(name, false)

        return
      }

      const valid = validate(['required'], value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  const TooltipComponent = disabled ? 'span' : Tooltip

  const defaultClasses = useStyles()

  return (
    <>
      <div
        className={clsx(defaultClasses.labelContainer, classes.labelContainer)}
      >
        <InputLabel className={clsx(defaultClasses.label, classes.label)}>
          <span style={label.color && { color: label.color }}>
            {translate(label.text || label)}
            {renderError && error && (
              <FormHelperText error={true}>{translate(error)}</FormHelperText>
            )}
          </span>
        </InputLabel>
        <TooltipComponent
          title={translate(
            `T_GENERAL_${type.toUpperCase()}_${value ? 'CHANGE' : 'ADD'}`
          )}
        >
          <IconButton
            color="primary"
            onClick={handleToggle('upload')}
            disabled={disabled}
          >
            <CreateOutlined
              className={clsx(defaultClasses.labelButton, classes.labelButton)}
            />
          </IconButton>
        </TooltipComponent>
        {ytCode && endpointYoutube && (
          <YoutubeThumbnail
            name={name}
            code={ytCode}
            endpoint={endpointYoutube}
            setValue={handleChange}
            setUpdatedAt={setUpdatedAt}
            formUrl={formUrl}
            formMethod={formMethod}
            disabled={disabled}
          />
        )}
        {iri && value && thumbs && !nodeRef && (
          <TooltipComponent title={translate('T_GENERAL_PHOTO_THUMBS')}>
            <IconButton
              color="primary"
              onClick={handleToggle('thumbs')}
              disabled={disabled}
            >
              <PhotoSizeSelectLargeOutlined
                className={clsx(
                  defaultClasses.labelButton,
                  classes.labelButton
                )}
              />
            </IconButton>
          </TooltipComponent>
        )}
        {value && (!validators || !validators.includes('required')) && (
          <TooltipComponent
            title={translate(`T_GENERAL_${type.toUpperCase()}_REMOVE`)}
          >
            <IconButton
              color="primary"
              onClick={handleToggle('remove')}
              disabled={disabled}
            >
              <DeleteForeverOutlined
                className={clsx(
                  defaultClasses.labelButton,
                  defaultClasses.deleteButton,
                  classes.labelButton,
                  classes.deleteButton,
                  disabled && defaultClasses.disabledButton
                )}
              />
            </IconButton>
          </TooltipComponent>
        )}
      </div>
      <UploadDialog
        name={name}
        type={type}
        hint={hint}
        endpoint={endpoint}
        disabled={disabled}
        validators={uploadValidators}
        setValue={setValue}
        setFormValue={setFormValue}
        handleChange={handleChange}
        setUpdatedAt={setUpdatedAt}
        formUrl={formUrl}
        formMethod={formMethod}
        accept={accept}
        isOpen={state.isUploadDialogOpen}
        handleToggle={handleToggle('upload')}
        isManagerAvailable={isManagerAvailable}
        restrictManagerMimeType={restrictManagerMimeType}
        handleFileManagerMetadata={handleFileManagerMetadata}
        definitions={definitions}
        translationLang={translationLang}
      />
      {value &&
        (type === 'image' ? (
          <img
            src={`${process.env.REACT_APP_BACKEND_ENTRYPOINT}/${value.url}`}
            className={clsx(defaultClasses.imagePreview, classes.imagePreview)}
            alt="preview"
          />
        ) : (
          <a
            className={clsx(defaultClasses.link, classes.link)}
            href={`${process.env.REACT_APP_BACKEND_ENTRYPOINT}/${value.url}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {value.originalName}
          </a>
        ))}
      {iri &&
        value &&
        thumbs &&
        (nodeRef ? (
          <ThumbCollection
            endpoint={thumbs.endpoint}
            parentIri={iri}
            originalPhotoUrl={value && value.url}
            disabled={disabled}
            twoColLayout={false}
            cropperNodeRef={nodeRef}
            align="left"
            classes={{ root: clsx(defaultClasses.thumbs, classes.thumbs) }}
            key={JSON.stringify(value)}
          />
        ) : (
          <ThumbsDialog
            endpoint={thumbs.endpoint}
            parentIri={iri}
            originalPhotoUrl={value && value.url}
            disabled={disabled}
            isOpen={state.isThumbsDialogOpen}
            handleToggle={handleToggle('thumbs')}
          />
        ))}
      {value && (!validators || !validators.includes('required')) && (
        <RemoveDialog
          name={name}
          type={type}
          setValue={handleChange}
          isOpen={state.isRemoveDialogOpen}
          handleToggle={handleToggle('remove')}
          disabled={disabled}
        />
      )}
    </>
  )
}

FileType.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['file', 'image']),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }),
  value: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  endpoint: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  formUrl: PropTypes.string.isRequired,
  formMethod: PropTypes.string.isRequired,
  iri: PropTypes.string,
  thumbs: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      endpoint: PropTypes.string.isRequired,
    }),
  ]),
  accept: PropTypes.string,
  ytCode: PropTypes.string,
  endpointYoutube: PropTypes.string,
  nodeRef: PropTypes.object,
  classes: PropTypes.shape({
    labelContainer: PropTypes.string,
    label: PropTypes.string,
    labelButton: PropTypes.string,
    deleteButton: PropTypes.string,
    imagePreview: PropTypes.string,
    link: PropTypes.string,
    thumbs: PropTypes.string,
  }),
}
