import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/config',
  access: 'config',
  resource: {
    definition: '#Config',
    properties: {
      freshApiKey: {
        type: FIELD_TYPES.TEXTAREA,
        label: '[MAILERLITE] Token',
      },
      // freshApiSecret: {
      //   type: 'string',
      //   label: '[FRESHMAIL] Secret',
      // },
    },
  },
}

export default schema
